@import '../../Styles/Mixin.scss';

#home{
  #banner{
    width: 100%;
    background: url(../../images/header/backgorund.jpg);
    background-size: cover;
    padding-bottom: 70px;
    .bg-grade{
      background: url(../../images/header/Vector-grade-connections.svg);
      background-size: cover;
      background-position: center;
      width: 2351px;
      height: 1322px;
      position: absolute;
      left: -300px;
      top: 0;
      overflow: hidden;
    }
    .content{
      z-index: 3;
    }
    .conteudo-banner{
      margin-bottom: 85px;
    }
    .icons-topo{
      width: 100%;
      height: 90px;
      align-items: center;
      justify-content: space-between;
      display: flex;
      margin-bottom: 65px;
      > div {
        margin-right: 20px;
      }
      img:last-child{
        margin-right: 0;
      }
    }
    .content-banner{
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      margin-top: 90px;
      .box-content{
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        position: relative;
        margin-left: 318px;
        img{
          margin-bottom: 22px;
        }
        .text-dias{
          font-weight: bold;
          font-size: 34.1026px;
          line-height: 41px;
          color: #E6DED7;
          margin-bottom: 20px;
          display: block;
        }
        h1{
          color: #fff;
          display: flex;
          align-items: flex-start;
          justify-content: center;
          flex-direction: column;
          span{
            font-size: 18px;
            margin-bottom: 7px;
            background: linear-gradient(90deg, #8F7ED7 0%, #57479C 100%);
            letter-spacing: 0.29em;
            padding: 10px 7px;
            font-family: 'graphie', sans-serif;
            font-weight: 700;
            &.effect{
              animation: effectbg 1s;
              animation-direction: alternate;
            }
          }
          .title1{
            max-width: 282.45px;
          }
          .title2{
            max-width: 156.33px;
          }
          .title3{
            max-width: 156.84px;
          }
          @keyframes effectbg {
            0% {
              width: 0;
              padding: 10px 0;
              letter-spacing: -0.6em;
            }
            100% {
              width: 100%;
              padding: 10px 7px;
              letter-spacing: 0.29em;
            }
          }
        }
        &:after{
          content: "";
          background: url(../../images/header/image-header.png);
          width: 292px;
          height: 304px;
          position: absolute;
          left: -318px;
          bottom: 14px;
        }
      }
      p{
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        font-family: 'graphie', sans-serif;
        color: #E6DED7;
        max-width: 567px;
        margin-top: 10px;
      }
    }
    .banner-right{
      min-height: 399px;
      position: relative;
      .icon-back{
        font-family: "termina", sans-serif;
        font-size: 14px;
        width: 100%;
        text-align: center;
        border: 1px solid #fff;
        padding: 0;
        left: 40px;
        bottom: 71px;
        color: #fff;
        text-transform: uppercase;
        letter-spacing: 0.28em;
        transition: 1s;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover{
          text-decoration: none;
          background: #8F7ED7 ;
          border: 1px solid #8F7ED7;
        }
      }
      .texto-termos{
        font-size: 12px;
        color: #fff;
        font-family: 'graphie', sans-serif;
        font-weight: 300;
        line-height: 18px;
        margin-top: 30px;
        a{
          font-weight: 700;
          text-decoration: underline;
          color: #fff;
          font-size: 12px;
          font-family: 'graphie', sans-serif;
        }
      }
    }
    .box-form{
      min-height: 399px;
      border-left: 1px solid #ffffff30;
      padding: 60px 0 60px 75px;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      text-align: left;
      .titulo{
        font-weight: bold;
        font-size: 22px;
        color: #8F7ED7;
      }
      .text{
        font-size: 18px;
        font-family: 'graphie', sans-serif;
        color: #fff;
        margin-bottom: 20px;
      }
      .termos{
        font-size: 12px;
        color: #fff;
        font-family: 'graphie', sans-serif;
        font-weight: 300;
        line-height: 18px;
        margin-top: 10px;
        a{
          font-weight: 700;
          text-decoration: underline;
          color: #fff;
          font-size: 12px;
          font-family: 'graphie', sans-serif;
        }
      }
      .buttons-form{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 25px 0;
        width: 100%;
        a{
          width: 48%;
        }
      }
      &.active{
        display: flex;
      }

      form{
        div.ipt-change{
          margin-top: 15px;
          > div{
            height: 35px;
          }
          span{
            display: block;
            color: #fff;
            font-family: 'graphie', sans-serif;
            font-weight: 700;
            font-size: 16px;
            margin-bottom: 7px;
          }
          label{
            margin: 0 25px 0 0;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            input[type="radio"]{
              width: 15px;
              height: 15px;
              margin-right: 10px;
            }
            p{
              display: block;
              color: #fff;
              font-family: 'graphie', sans-serif;
              font-weight: 700;
              font-size: 16px;
            }
            &:last-child{
              margin-right: 0;
            }
          }

        }
        .ipt-select{
          display: none;
          opacity: 0;
          transition: 0.5s;
          &.active{
            display: block;
            opacity: 1;
            transition: 0.5s;
          }
        }
        label.ipt{
          margin-top: 15px;
          span{
            display: block;
            color: #fff;
            font-family: 'graphie', sans-serif;
            font-weight: 700;
            font-size: 16px;
            margin-bottom: 7px;
          }
          input[type="text"],
          input[type="tel"],
          input[type="email"],
          select{
            display: block;
            background: #00000061;
            border: 0.5px solid #8F7ED7;
            box-sizing: border-box;
            height: 35px;
            width: 100%;
            color: #fff;
            padding-left: 10px;
            font-size: 14px;
            font-family: 'graphie', sans-serif;
          }
        }
        label.ckbox{
          margin-top: 15px;
          margin-bottom: 25px;
          span{
            color: #fff;
            font-family: 'graphie', sans-serif;
            font-weight: 700;
            font-size: 16px;
            margin-bottom: 7px;
            margin-left: 10px;
          }  
        }
        input[type="submit"]{
          font-family: "termina", sans-serif;
          font-size: 14px;
          width: 50%;
          text-align: center;
          border: 1px solid #8f7ed7;
          padding: 0;
          left: 40px;
          bottom: 71px;
          color: #fff;
          text-transform: uppercase;
          letter-spacing: 0.28em;
          transition: 0.2s;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: linear-gradient(90deg,#8f7ed7,#57479c);
          cursor: pointer;
          &:hover{
            text-decoration: none;
            background: linear-gradient(90deg,#57479c,#8f7ed7);
            border: 1px solid #8F7ED7;
          }
        }
      }

    }
    .box-full{
      background: rgba(0,0,0,0.2500000);
      height: 162px;
      display: flex;
      align-items: center;
      justify-content: center;
      .box{
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
        flex-direction: column;
        height: 105px;
        padding: 0 50px;
        span{
          font-size: 20px;
          font-weight: 700;
          margin-bottom: 5px;
          margin-top: 10px;
          text-transform: uppercase;
        }
        p{
          font-weight: 300;
          font-size: 16px;
          margin-bottom: 5px;
        }
        &:nth-child(2){
          border-right: 1px solid #ffffff30;
          border-left: 1px solid #ffffff30;
          span{
            margin-top: 14.5px;
          }
        }
      }
    }

    .programacao-box{
      padding: 50px 80px;
      position: relative;
      .dates{
        border-bottom: 3px solid #8F7ED7;
        a{
          color: #57479C;
          font-size: 28px;
          background: #fff;
          font-weight: 700;
          transition: 0.5s;
          margin: 0 9.5px;
          width: 155px;
          height: 48px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 25px;
          &:hover{
            color: #E6DED7;
            background: #57479C;
            text-decoration: none;
          }
          &.active{
            color: #E6DED7;
            background: #57479C;
            text-decoration: none;
          }
        }
      }
      .daily-programation{
        display: none;
        position: relative;
        &.active{
          display: block;
        }
        .arrow-left{
          position: absolute;
          left: -55px;
          bottom: calc(50% - 21.98px);
          transition: 0.5s;
          &:hover{
            left: -60px;
          }
          &.ard:hover{
            left: -55px;
          }
        }
        .arrow-right{
          position: absolute;
          right: -55px;
          bottom: calc(50% - 21.98px);
          transition: 0.5s;
          &:hover{
            right: -60px;
          }
          &.ard:hover{
            right: -55px;
          }
        }
      }
    }

  }

  #sobre{
    padding: 97px 0;
    z-index: 1;
    position: relative;
    background: #fff;
    h2{
      font-weight: 700;
      line-height: 46px;
      margin-bottom: 19px;
      font-size: 38px;
    }
    .subtitle{
      font-weight: 700;
      line-height: 36px;
      margin-bottom: 25px;
      font-size: 30px;
    }
    .text{
      line-height: 150%;
      font-weight: 300;
      margin-bottom: 45px;
    }
    .images{
      position: relative;
      .bg-icons{
        position: absolute;
        right: 0;
        top: -50px;
        width: 34.87px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        > div{
          margin-bottom: 23px;
        }
      }
      .bg-sobre{
        position: absolute;
        top: 42px;
        right: 0;
      }
      .imagem-sobre1{
        position: absolute;
        top: 0;
        left: 60px;
      }
      .imagem-sobre2{
        position: absolute;
        bottom: -35px;
        right: 0;
      }
    }
    a.bt{
      width: 300px;
    }
  }

  #convidados{
    background: #E5E5E5;
    padding: 80px 0 80px 0;
    position: relative;
    h3{
      font-weight: 700;
      font-size: 38px;
      color: #242424;
      position: relative;
      padding-left: 100px;
      margin-bottom: 25px;
      &:after{
        content: "";
        width: 82px;
        height: 3px;
        background: #57479C;
        position: absolute;
        left: 0;
        top: 20px;
        border-radius: 100px;
      }
    }
    .font-text{
      font-size: 16px;
      font-weight: 300;
      line-height: 150%;
      margin-bottom: 55px;
    }
    .box-image{
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      width: 238px;
      z-index: 2;
      h4{
        position: absolute;
        left: 15px;
        bottom: 20px;
        color: #8F7ED7;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        transition: 0.5s;
      }
      &:hover{
        h4{
          color: #fff;
        }
      }
    }
    .link-box{
      cursor: pointer;
    }
    .desc-convidado{
      font-size: 16px;
      font-family: 'graphie', sans-serif;
      width: 238px;
      margin-bottom: 45px;
      margin-top: 15px;
      height: 72px;
      white-space: pre-wrap;
      white-space: pre-line;
    }
    &:after{
      content: "";
      background: url(../../images/convidados/obj1.svg);
      width: 222px;
      height: 480px;
      position: absolute;
      right: 0;
      top: 150px;
    }
    &:before{
      content: "";
      background: url(../../images/convidados/obj2.svg);
      width: 281px;
      height: 390px;
      position: absolute;
      left: 0;
      bottom: 100px;
    }
    
    .box-vermais{
      display: none;
      &.active{
        display: flex;
      }
    }

    .buttons{
      .bt-vermais{
        color: #8F7ED7;
        font-size: 35px;
        font-weight: 700;
        padding-bottom: 2px;
        border-bottom: 2px solid #8F7ED7;
        font-family: 'graphie', sans-serif;
        &.inactive{
          display: none;
        }
      }
    }
  }

  #mediadores{
    background: #E5E5E5;
    padding: 0 0 130px 0 ;
    display: none;
    h3{
      font-weight: 700;
      font-size: 38px;
      color: #242424;
      position: relative;
      padding-left: 100px;
      margin-bottom: 60px;
      margin-top: 60px;
      &:after{
        content: "";
        width: 82px;
        height: 3px;
        background: #57479C;
        position: absolute;
        left: 0;
        top: 20px;
        border-radius: 100px;
      }
    }
    .line-top{
      border-top: 2px solid #DADADA;
    }
    .bordar-med{
      padding: 0 180px;
    }
    .box-mediadores{
      margin-bottom: 40px;
      height: 80px;
      max-width: 245px;
      min-width: 245px;
      h4{
        font-size: 20px;
        color: #57479C;
        font-weight: 700;
        margin-bottom: 0;
      }
      span{
        font-family: 'graphie', sans-serif;
        font-size: 16px;
      }
      &:last-child{
        margin-bottom: 0;
      }
    }

  }

  #programacao{
    background: url(../../images/programacao/background.svg);
    background-size: cover;
    h3{
      font-size: 38px;
      color: #57479C;
      font-weight: 700;
      margin-top: 100px;
      margin-bottom: 50px;
    }
    .programacao-box{
      background: #221C48;
      padding: 50px 80px;
      position: relative;
      .dates{
        border-bottom: 3px solid #8F7ED7;
        a{
          color: #57479C;
          font-size: 28px;
          background: #191537;
          font-weight: 700;
          transition: 0.5s;
          margin: 0 9.5px;
          width: 155px;
          height: 48px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 25px;
          &:hover{
            color: #E6DED7;
            background: #57479C;
            text-decoration: none;
          }
          &.active{
            color: #E6DED7;
            background: #57479C;
            text-decoration: none;
          }
        }
      }
      .daily-programation{
        display: none;
        position: relative;
        .resume{
          margin-top: 40px;
          .date{
            font-size: 18px;
            font-weight: 300;
            color: #fff;
            display: block;
            text-transform: uppercase;
            margin-bottom: 10px;
            b{
              font-weight: 700;
            }
          }
          .palestrante{
            font-family: 'graphie', sans-serif;
            font-size: 20px;
            color: #fff;
            font-weight: 700;
            display: block;
          }
          .mediador{
            font-family: 'graphie', sans-serif;
            font-size: 16px;
            color: #57479C;
            display: block;
            font-weight: 700;
            margin-bottom: 10px;
          }
          .text{
            color: #fff;
            font-family: 'graphie', sans-serif;
            font-size: 16px;
            line-height: 150%;
            display: block;
          }
        }
        &.active{
          display: block;
        }
        .arrow-left{
          position: absolute;
          left: -55px;
          bottom: calc(50% - 21.98px);
          transition: 0.5s;
          &:hover{
            left: -60px;
          }
          &.ard:hover{
            left: -55px;
          }
        }
        .arrow-right{
          position: absolute;
          right: -55px;
          bottom: calc(50% - 21.98px);
          transition: 0.5s;
          &:hover{
            right: -60px;
          }
          &.ard:hover{
            right: -55px;
          }
        }
      }
      &:before{
        content: "";
        background: url(../../images/programacao/image.svg);
        width: 352px;
        height: 377px;
        position: absolute;
        right: -152px;
        bottom: -239px;
      }
    }
    .full-button{
      margin-top: 60px;
      display: block;
    }
  }

  #patrocinadores{
    padding-top: 130px;
    padding-bottom: 100px;
    h3{
      font-weight: 700;
      font-size: 38px;
      color: #242424;
      position: relative;
      padding-left: 100px;
      margin-bottom: 70px;
      &:after{
        content: "";
        width: 82px;
        height: 3px;
        background: #57479C;
        position: absolute;
        left: 0;
        top: 20px;
        border-radius: 100px;
      }
    }
    .patrocinadores-images{
      display: flex;
      align-items: center;
      justify-content: center;
      img{
          margin: 0 30px;
        &:last-child{
          margin-right: 0;
        }
        &:first-child{
          margin-left: 0;
        }
      }
    }
  }

  #apoio{
    background: #E6E6E6;
    padding: 34px 0;
    span{
      color: #4F4F4F;
      font-size: 24.3161px;
      font-weight: 700;
      margin-bottom: 25px;
      text-align: center;
      display: block;
    }
    .apoio-images{
      display: flex;
      align-items: center;
      justify-content: center;
      img{
        margin-right: 50px;
      }
      img:last-child{
        margin-right: 0px;
      }
      .logosvnl{
        width: 100px;
      }
      .trama{
        width: 93px;
      }
    }
  }

  /* FORM CLIENTES */
  .j-client{
    display: none;
    transition: 1s;
    &.active{
      display: block;
    }
  }
  .n-client{
    transition: 1s;
    display: flex;
    &.active{
      display: block;
    }
  }
}


@media screen and (max-width: 750px){
  #home{
    width: 750px;
  }
}