@media screen and (max-width: 1400px){
    .content{
        width: 95% !important;
    }
}
@media screen and (max-width: 750px){
    body{
        max-width: 750px;
        overflow-x: hidden;
    }
    .content{
        width: 90%;
    }
    .desk-none{
		display: flex !important;
	}
	.mb-none{
		display: none !important;
    }
    #header{
        position: absolute;
        width: 100%;
        z-index: 9;
        top: 0;
        margin-top: 0;
        height: 112px;
        .menu-mobile{
            a{
                margin-bottom: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                img{
                    width: 45px;
                }
                .img-left{
                    margin-right: 15px;
                    margin-top: -10px;
                }
                .whta{
                    margin-top: -10px;
                }
            }
            .bt{
                text-align: center;
                font-size: 38px;
            }
            .icon-rede{
                margin-top: 25px;
                width: 55px;
                display: inline-block;
                float: left;
                margin-right: 25px;
            }
        }
    }
    #banner{
        background: url(../images/header/bg-mobile.jpg) !important;
        background-size: cover !important;
        .icons-topo{
            img{
                display: none !important;
            }
        }
        .bg-grade{
            display: none;
        }
        .button-form {
            font-size: 17px !important;
            padding: 24px 0 !important;
        }
        .box-form .titulo {
            font-size: 35px !important;
        }
        .box-form .text {
            font-size: 26px !important;
        }
        .banner-right .texto-termos {
            font-size: 22px !important;
            line-height: 31px !important;
            text-align: justify;
            a{
                font-size: 22px !important;
            }
        }
        .banner-right .termos {
            font-size: 21px !important;
            line-height: 31px !important;
            text-align: justify;
            a{
                font-size: 21px !important;
            }
        }
        .box-content{
            margin-right: 0 !important;
        }
        .banner-right{
            margin-top: 60px;
            min-height: 270px !important;
            position: relative !important;
            margin-bottom: 60px;
        }
        .box-form{
            padding: 0 40px !important;
            border-left: none !important;
            min-height: auto !important;
        }
        .conteudo-banner{
            margin-bottom: 0 !important;
          }
        .box-full{
            justify-content: center !important;
            height: 265px !important;
            .box{
                width: 33%;
                padding:0 0 0 40px !important;
                height: auto !important;
                img{
                    width: 60px;
                }
                span{
                    font-size: 26px !important;
                } 
                p{
                    font-size: 24px !important;
                }
            }
            .box:nth-child(2) {
                border-right: 1px solid #ffffff30;
                border-left: 1px solid #ffffff30;
                padding: 0 0 0 60px !important;
                margin: 0 !important;
            }
        }
        .content-banner {
            align-items: center !important;
            justify-content: center !important;
            flex-direction: column !important;
            padding-bottom: 0 !important;
            margin-top: 115px !important;
        }

    }
    #sobre{
        .img-mobile{
            width: 100%;
            margin-top: 50px;
        }
        .text{
            font-size: 25px;
            text-align: justify;
        }
        a{
            font-size: 17px !important;
            padding: 24px 0 !important;
            width: 350px !important;
        }
    }

    #convidados{
        &:after{
            background: none !important;
            width: 0 !important;
            height: 0 !important;
        }
       .font-text{
        font-size: 25px !important;
       } 
       .box-image{
           width: 300px !important;
           img{
               width: 300px;
           }
           h4{
            font-size: 20px !important;
            line-height: 28px !important;
           }
       }
       .desc-convidado {
            font-size: 21px !important;
            width: 300px !important;
        }
        .buttons{
            margin-top: 45px;
            .bt-pd{
                font-size: 17px !important;
                padding: 24px 0 !important;
                width: 300px !important;
            }
        }

        .bt-pd{
            font-size: 17px !important;
            padding: 18px 0 !important;
            width: 335px !important;
            margin-top: 55px;
        }
    }

    #mediadores{
        padding: 0 0 0px 0 !important;
        .box-mediadores{
            max-width: 100% !important;
            height: 195px !important;
            h4 {
                font-size: 30px !important;
            }
            span{
                font-size: 27px !important;
                padding-right: 30px !important;
                display: block;
            }
            &:last-child{
                margin-bottom: 40px !important;
            }
        } 
    } 

    #programacao{
        .programacao-box{
            padding: 50px 0 !important;
            &:before{
                background: none !important;
                width: 0 !important;
                height: 0 !important;
            }
        }
        .daily-programation{
            .arrow-left,
            .arrow-right{
                display: none;
            }
            .resume {
                margin-top: 40px !important;
                padding: 0 25px !important;
                .date{
                    font-size: 27px !important;
                }
                .palestrante{
                    font-size: 25px !important;
                }
                .mediador{
                    font-size: 24px !important;
                }
                .text{
                    font-size: 24px !important;
                }
            }
        }
        .bt{
            font-size: 17px !important;
            padding: 24px 0 !important;
            width: 300px !important;
        }
    }

    #footer{
        #footer-1{
            height: 275px !important;
            .links-redes{
                margin-bottom: 33px;
                img{
                    width: 65px !important;
                    margin-right: 15px !important;
                    &:last-child{
                        margin-right: 0;
                    }
                }
            }
            .logo-footer{
                width: 350px;
            }
        }
        #footer-2{
            height: 52px !important;
            font-size: 21px !important;
            .ft-2div,
            .ft-div{
                justify-content: center !important;
            }
        }
    }

    #header-mobile{
        height: 100px;
        .ctr-logo{
            height: 100px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            img{
                margin-left: 25px;
            }
        }
   
    }
    
    #modal{
        #modalContent{
            left: calc(50% - 375px);
            .close-modal {
                right: 40px;
                top: 30px;
                font-size: 60px;
            }
            .box-modal-convidado{
                min-width: 750px;
                max-width: 750px;
                .content-right {
                    text-align: left;
                    font-size: 28px;
                    line-height: 39px;
                    margin-top: 25px !important;
                    display: block;
                    white-space: unset;
                }
                .content-left p {
                    left: 185px !important;
                }
            }
        }
    }

    #modal.active #modalContent {
        top: 0;
    }
    .box-modal-convidado{
        height: 100vh;
    }

    .n-client,
    .j-client{
        margin: 0px 0 30px 0;
        .text{
            margin-bottom: 15px;
        }
        label.ipt{
            span{
                font-size: 24px !important;
            }
            input[type="text"],
            input[type="tel"],
            input[type="email"],
            select{
                height: 60px !important;
                font-size: 24px !important;
            }
        }
        label.ckbox{
            margin-top: 30px !important;
            span{
                font-size: 24px !important;
            }
            input[type="checkbox"]{
                height: 30px;
                width: 30px;
                display: inline-block;
            }
        }
        input[type="submit"],
        .icon-back{
            margin-top: 15px;
            height: 60px !important;
            font-size: 23px !important;
            width: 100% !important;
        }
        .texto-termos{
            margin-bottom: 45px;
        }
    }

    div.ipt-change{
        margin-top: 15px;
        > div{
          height: 60px !important;
        }
        span{
          font-size: 24px !important;
        }
        label{
          margin: 0 25px 0 0;
          display: flex;
          align-items: center;
          justify-content: flex-start;
            input[type="radio"]{
                width: 30px !important;
                height: 30px !important;
            }
            p{
                font-size: 24px !important;


            }
        }
    }
    .patrocinadores-images{
        flex-wrap: wrap;
        img:nth-child(3){
            margin-top: 45px !important;
        }
        img:nth-child(4){
            margin-top: 45px !important;
        }
    }

    #home #banner .content-banner p {
        font-size: 24px !important;
        max-width: 100% !important;
        text-align: justify !important;
    }
}