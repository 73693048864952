$color_purple: #57479C;
$color_grey: #E6DED7;

/* FONTS */
.font-text{
    font-size: 16px;
}
/* COLORS */
.cl-purple{
    color: $color_purple;
}
.cl-grey{
    color: $color_grey;
}
.cl-white{
    color: #fff;
}
.cl-black{
    color: #222222;
}
/* BGS */
.bg-purple{
    background-color: $color_purple;
}

/* WHEGHT */
.font-wheight-700{
    font-weight: 700;
}
.font-wheight-600{
    font-weight: 600;
}
.font-wheight-500{
    font-weight: 500;
}
.font-wheight-400{
    font-weight: 400;
}
.font-weight-300{
    font-weight: 300;
}
.font-weight-700{
    font-weight: 700;
}
.font-weight-800{
    font-weight: 800;
}
.font-weight-600{
    font-weight: 600;
}
.font-weight-500{
    font-weight: 500;
}
.font-weight-400{
    font-weight: 400;
}
.font-weight-300{
    font-weight: 300;
}