@import url("https://use.typekit.net/bex5ftv.css");
@import './Mixin.scss';
@import './hamburguer-menu.scss';

*{
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
}
ul{
    margin: 0;
    padding: 0;
    list-style-type: none;
    li{
        margin: 0;
        padding: 0;
        list-style-type: none;
    }
}
p{
    margin: 0;
}
a{
    color: #000;
    text-decoration: none;
    font-size: 16px;
}

body{
    font-family: 'termina', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: #000;
    overflow-x: hidden;
}
input, button, textarea{
    font-family: 'termina', sans-serif;
    font-size: 16px;
    font-weight: 400;
}
.font-graphie{
    font-family: 'graphie', sans-serif;
    font-size: 16px;
    font-weight: 400;
}
.desk-none{
    display: none !important;
}
.mb-none{
    display: block;
}
.clear{
    clear: both;
}
.content{
    box-sizing: border-box;
    position: relative;
    width: 100%;
    max-width: 1350px;
    margin-right: auto;
    margin-left: auto;
}
.bt-pd{
    background: linear-gradient(90deg, #8F7ED7 0%, #57479C 100%);
    font-size: 14px;
    color: #fff;
    width: 211px;
    display: inline-block;
    text-align: center;
    padding: 10px 0;
    letter-spacing: 0.28em;
    text-transform: uppercase;

    -webkit-transition: background 1s ease-out;  
    -moz-transition: background 1s ease-out;  
    -o-transition: background 1s ease-out;  
    transition: background 1s ease-out;  
    .icon{
        margin-left: 5px;
    }
    &:hover{
        background: linear-gradient(90deg, #57479C 0%, #8F7ED7 100%); 
    }
}
.bt:hover{
    color: #fff;
    text-decoration: none;
}
.button-form{
    background: #E6DED7;
    font-size: 14px;
    color: #221C48;
    display: inline-block;
    text-align: center;
    padding: 10px 0;
    letter-spacing: 0.28em;
    text-transform: uppercase;

    -webkit-transition: background 0.4s ease-out;  
    -moz-transition: background 0.4s ease-out;  
    -o-transition: background 0.4s ease-out;  
    transition: background 0.4s ease-out; 
    .icon{
        margin-left: 5px;
    }
    &:hover{
        background: #8F7ED7;
        color: #E6DED7;
        text-decoration: none;
    }
}
.clear{
    clear: both;
}

/* HEADER */
#header{
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 4;
    img{
        margin-bottom: -70px;
    }
    #header-browser{
        height: 90px;
        align-items: center;
        justify-content: space-between;
        display: flex;
        a{
            color: #ffff;
            margin-right: 35px;
            font-size: 20px;
            cursor: pointer;
            position: relative;
        }
        a:before{
            content: "";
            width: 100%;
            height: 3px;
            background: #8F7ED7;
            position: absolute;
            left: 0;
            bottom: -7px;
            border-radius: 100px;
        }
        a:after{
            content: "";
            width: 0;
            height: 3px;
            background: #57479C;
            transition: 0.5s;
            border-radius: 100px;
        }
        a:hover:after{
            content: "";
            width: 100%;
            height: 3px;
            background: #57479C;
            position: absolute;
            left: 0;
            bottom: -7px;
        }
        a:last-child{
            margin-right: 0;
        }
        a:hover{
            text-decoration: none;
        }
    }
}

/* FOOTER */

#footer{
    #footer-1{
        background: #242424;
        height: 170px;
        display: flex;
        align-items: center;
        justify-content: center;
        .links-redes{
            display: flex;
            align-items: center;
            justify-content: center;
            a{
                margin-right: 13px;
                &:last-child{
                    margin-right: 0;
                }
            }
        }
        .logo-footer{
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .links{
            display: flex;
            justify-content: flex-end;
            align-items: center;
            div{
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: flex-end;
                .link-footer{
                    font-size: 12px;
                    font-weight: 300;
                    color: #fff;
                    span{
                        font-weight: 700;
                    }
                }
                .link-politicas{
                    color: #8F7ED7;
                    font-size: 12px; 
                }
            }

        }
    }
    #footer-2{
        background: #1F1F1F;
        color: #fff;
        height: 52px;
        font-size: 12px;
        font-family: 'graphie', sans-serif;
        .ft-div{
            display: flex;
            align-items: center;
            height: 52px;
            justify-content: center;
        }
        .ft-2div{
            display: flex;
            align-items: center;
            height: 52px;
            justify-content: flex-end;
            a{
                color: #fff;
                font-weight: 700;
                font-size: 12px;
            }
        }
    }
}

/* MODAL */

.box-modal-convidado{
    background: url(../images/modal/bg-modal.jpg);
    min-width: 1000px;
    min-height: 470px;
    background-size: cover;
    display: block;
    position: relative;
    padding: 70px 60px;
    .close-modal{
        color: #fff;
        position: absolute;
        right: 15px;
        top: 10px;
        background: none;
        border: none;
        font-size: 20px;
        font-weight: 700;
        outline: none;
        cursor: pointer;
    }
    .content-left{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 100%;
        img{
            width: 238px;
        }
        p{
            position: absolute;
            font-size: 28px;
            color: #fff;
            font-weight: 700;
            white-space: pre-line;
            left: 185px;
            bottom: 29px;
            line-height: 34px;
        }
    }
    .content-right{
        text-align: right;
        font-family: 'graphie', sans-serif;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        color: #fff;
        height: 100%;
        font-weight: 400;
        white-space: pre-wrap;
        white-space: pre-line;
        .normal {
            word-break: normal;
        }
    }
}