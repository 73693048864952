/* Position and sizing of burger button */
.bm-burger-button {
  position: absolute;
  width: 36px;
  height: 30px;
  right: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #fff;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #fff;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 45px !important;
  width: 45px !important;
  top: 25px !important;
  right: 45px !important;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #fff;
  width: 9px !important;
  height: 42px !important;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: url(../images/header/backgorund.jpg);
  background-size: cover;
  padding: 0 3.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

/* Individual item */
.bm-item {
  font-size: 70px;
  margin-bottom: 15px;
  color: #fff;
  font-family: 'graphie', sans-serif;
}
.bm-item:hover{
  color: #fff;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
  left: 0;
}

.bm-menu-wrap{
  top: 0;
}



